import { indResizeFunc, responsibleCheck } from './_resizecheck';

//レスポンシブによる初期化やClass外し
export function responsResetFunc() {
    var resizecheck, hGlobal;
    indResizeFunc();

    //resizecheck = $('#responsibleCheck').width();
    const spAdjustColumn = '.spAdjustColumn',
        subNav = '#subNav',
        globalMenu = '.globalMenu',
        spHome = '.spHome',
        dlList = 'dl.list';

    if (2 != responsibleCheck) {

        //ヘッダーリセット処理
        $('.spAdjustColumn,.globalMenus,.spHome,.globalmenusColumn').removeAttr(
            'style'
        );

        // footer処理
        // $(dlList)
        //     .find('dt')
        //     .removeClass('open');
        // $(dlList)
        //     .find('dd')
        //     .removeAttr('style');
    }
}

//サブナビゲーション処理
export function subNavigationsFunc() {

    // var naviColumn, navListLayout, flag;
    // naviColumn = '.naviColumn';
    // navListLayout = '.navListLayout';
    // $(naviColumn).on('click', function() {
    //     $(this).toggleClass('open');
    //     flag = $(navListLayout).css('display');
    //     if ('none' == flag) {
    //         $(navListLayout).slideDown(500);
    //     } else {
    //         $(navListLayout).slideUp(500);
    //     }
    // });
}
