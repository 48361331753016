/**
 *高さを揃えるjs
 *クラス名に"heightLine-xxx"と、xxx部分を高さを揃えたい要素に追加
 *クラス名に"heightLineSp-xxx"とするとSP版のみに対応。
 *クラス名に"heightLineAll-xxx"とするとPCSP両方に対応。
 **/
export var heightLineSet = [],
    heightLineSetSp = [],
    heightLineSetAll = [],
    heightLineArry = [],
    heightLineClass,
    heightLineMax,
    heightLineCheck,
    resizeWidth,
    i,
    heightLineSetFunc,
    heightLineFunc;
export function heightLine() {
    heightLineSetFunc = function() {

        // PC用
        $('[class*=\'heightLine-\'],[class*=\'heightLineAll-\']').each(function(i) {
            heightLineArry = $(this)
                .attr('class')
                .split(' ');
            for (i = 0; heightLineArry.length > i; i++) {

                //alert('調査対象：' + heightLineArry[i]);
                if (-1 != heightLineArry[i].indexOf('' + 'heightLine-') || -1 != heightLineArry[i].indexOf('' + 'heightLineAll-')) {

                    //alert('一致：' + heightLineArry[i]);
                    heightLineClass = '.' + heightLineArry[i];
                    break;
                }
            }
            if (0 > heightLineSet.indexOf(heightLineClass)) {
                heightLineSet.push(heightLineClass);
            }
        });

        // SP用
        $('[class*=\'heightLineSp-\'],[class*=\'heightLineAll-\']').each(function(i) {
            heightLineArry = $(this)
                .attr('class')
                .split(' ');
            for (i = 0; heightLineArry.length > i; i++) {

                //alert('調査対象：' + heightLineArry[i]);
                if (-1 != heightLineArry[i].indexOf('' + 'heightLineSp-') || -1 != heightLineArry[i].indexOf('' + 'heightLineAll-')) {

                    //alert('一致：' + heightLineArry[i]);
                    heightLineClass = '.' + heightLineArry[i];
                    break;
                }
            }
            if (0 > heightLineSetSp.indexOf(heightLineClass)) {
                heightLineSetSp.push(heightLineClass);
            }
        });
        heightLineFunc();
    };

    heightLineFunc = function() {
        if (0 < heightLineSet.length || 0 < heightLineSetSp.length || 0 < heightLineSetAll.length) {
            resizeWidth = $('#responsibleCheck').width();
            if (1 == resizeWidth) {

                //SP用クリア
                for (i = 0; i < heightLineSetSp.length; i++) {
                    $(heightLineSetSp[i]).css('height', 'auto');
                }

                //PC用
                for (i = 0; i < heightLineSet.length; i++) {
                    heightLineMax = 0;
                    $(heightLineSet[i]).css('height', 'auto');
                    $(heightLineSet[i]).each(function(i2) {
                        heightLineCheck = $(heightLineSet[i])
                            .eq(i2)
                            .outerHeight();
                        if (heightLineCheck > heightLineMax) {
                            heightLineMax = heightLineCheck;
                        }
                    });
                    heightLineMax = heightLineMax + 'px';
                    $(heightLineSet[i]).css('height', heightLineMax);
                }
            } else {

                //PC用クリア
                for (i = 0; i < heightLineSet.length; i++) {
                    $(heightLineSet[i]).css('height', 'auto');
                }

                //SP用
                for (i = 0; i < heightLineSetSp.length; i++) {
                    heightLineMax = 0;
                    $(heightLineSetSp[i]).css('height', 'auto');
                    $(heightLineSetSp[i]).each(function(i2) {
                        heightLineCheck = $(heightLineSetSp[i])
                            .eq(i2)
                            .outerHeight();
                        if (heightLineCheck > heightLineMax) {
                            heightLineMax = heightLineCheck;
                        }
                    });
                    heightLineMax = heightLineMax + 'px';
                    $(heightLineSetSp[i]).css('height', heightLineMax);
                }
            }
        }
    };
}
