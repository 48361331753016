// jQueryプラグイン
import 'jquery.easing';
import { indResizeFunc, responsibleCheck } from './_resizecheck';

/* ----------------------------------------------------------------------------------
ページ内リンクのアニメーション
---------------------------------------------------------------------------------- */
export function pageLinkAnimationFunc() {
    const $jsTop = $('#jsTop');
    $jsTop.on('click', function() {
        $('body,html').animate(
            {
                scrollTop: 0
            },
            700,
            'easeInOutQuint'
        );
        return false;
    });
}

export function pageLinkNavFunc() {

    //URLのハッシュ値を取得
    let urlHash = location.hash;
    let hash;

    //ハッシュ値があればページ内スクロール
    if (urlHash) {

        //スクロールを0に戻す
        $('body,html')
            .stop()
            .scrollTop(0);
        setTimeout(function() {

            //ロード時の処理を待ち、時間差でスクロール実行
            scrollToAnker(urlHash);
        }, 100);
    }

    $('a[href^="#"]').click(function() {
        const href = $(this).attr('href');
        hash = $('#' == href || '' == href ? 'html' : href);
        scrollToAnker(hash);
        return false;
    });

    function scrollToAnker(hash) {
        const eleHeight = $('#masthead').outerHeight(true);
        const target = $(hash);
        const position = target.offset().top;
        $('html, body').animate(
            {
                scrollTop: position - eleHeight
            },
            700,
            'easeInOutQuint'
        );
        return false;
    }
}
