import { indResizeFunc, responsibleCheck } from './_resizecheck';
export function footerFunc() {

    // var dlList = 'dl.list';
    // indResizeFunc();
    // $(dlList)
    //     .find('dt')
    //     .on('click', function() {
    //         if ('2' == responsibleCheck) {
    //             $(this)
    //                 .stop()
    //                 .toggleClass('open')
    //                 .next('dd')
    //                 .stop()
    //                 .slideToggle(500);
    //         }
    //     });
}
