import { indResizeFunc, responsibleCheck } from './_resizecheck';

//newsページで使わる処理等
export function newsAllFunc() {
    indResizeFunc();
    var nArchivesColumn;
    nArchivesColumn = '.archivesColumn';

    //ホバー処理
    $(nArchivesColumn).hover(
        function() {
            if (2 != responsibleCheck) {
                $(this)
                    .find('ul li')
                    .addClass('open');
                $(this)
                    .find('ul li')
                    .eq(1)
                    .stop()
                    .slideDown(500);
            }
        },
        function() {
            if (2 != responsibleCheck) {
                $(this)
                    .find('ul li')
                    .removeClass('open');
                $(this)
                    .find('ul li')
                    .eq(1)
                    .stop()
                    .slideUp(500);
            }
        }
    );

    //SP処理
    $(nArchivesColumn).on('click', function() {
        if (2 == responsibleCheck) {
            if (
                $(this)
                    .find('ul li')
                    .eq(0)
                    .hasClass('open')
            ) {
                $(this)
                    .find('ul li')
                    .removeClass('open');
                $(this)
                    .find('ul li')
                    .eq(1)
                    .stop()
                    .slideUp(500);
            } else {
                $(this)
                    .find('ul li')
                    .eq(0)
                    .addClass('open');
                $(this)
                    .find('ul li')
                    .eq(1)
                    .stop()
                    .slideDown(500);
            }
        }
    });
}

//セミナー関連処理
export function seminarAllFunc() {
    var holdingTerminationColumn;
    holdingTerminationColumn = '.holdingTerminationColumn';
    $(holdingTerminationColumn)
        .find('dl')
        .eq(0)
        .find('dt')
        .addClass('open');
}

//アコーディオン系
export function accordionSetFunc() {
    var sJsDt, jsClose;
    sJsDt = '.sJsDt';
    jsClose = '#jsClose';
    $(sJsDt).on('click', function() {
        $(this).toggleClass('open');
        if ($(this).hasClass('open')) {
            $(this)
                .next('dd')
                .stop()
                .slideDown(500);
        } else {
            $(this)
                .next('dd')
                .stop()
                .slideUp(500);
        }
    });
    $(jsClose).on('click', function() {
        $(this)
            .parent()
            .stop()
            .slideUp(500);
        $(this)
            .parent()
            .prev('dt')
            .removeClass('open');
    });
}

//レポート関連処理
export function reportAllFunc() {
    var past = '.past';
    $(past).on('click', function() {
        var status;
        $(this).toggleClass('open');
        status = $(this)
            .parent()
            .next()
            .css('display');
        if ('none' == status) {
            $(this)
                .parent()
                .next()
                .stop()
                .slideToggle(500);
        } else {
            $(this)
                .parent()
                .next()
                .stop()
                .slideToggle(500);
        }
    });
}
