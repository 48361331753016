import { indResizeFunc, responsibleCheck } from './_resizecheck';

//変数名(要素名を記載)
let $header, $mainContent, $subNavigations, searchForm, globalmenusColumn;

// prettier-ignore
$header            = $('#masthead'),
$mainContent       = $('#main'),
$subNavigations    = $('#siteNavigation'),
searchForm        = '#searchForm',
globalmenusColumn = '.globalmenusColumn';

export function headerHeightFunc() {
    var eleHeight, spHomeHeight, logoHeight, spHomeHeightTop;
    indResizeFunc();
    const $logo = $('.siteLogo');

    //ヘッダーの高さを取得
    // eleHeight = $header.outerHeight(true);
    // logoHeight = $logo.outerHeight(true);
    // logoHeight = Math.round(logoHeight);
    // logoHeight = parseInt(logoHeight);

    //検索Formの高さ取得
    // $(searchForm).css('height', logoHeight);
    // spHomeHeight = $('.spHome').outerHeight(true);
    // spHomeHeight = Math.round(spHomeHeight);
    // spHomeHeight = parseInt(spHomeHeight);

    //sp時のサブナビゲーション&ハンバーガーメニューの調整
    if ('2' == responsibleCheck) {

        // $mainContent.css('padding-top', logoHeight);
        // $('.spAdjustColumn').css('padding-top', logoHeight);
        // $(globalmenusColumn).css('height', logoHeight);
        // spHomeHeightTop = logoHeight - spHomeHeight;
        // $('.spHome').css({
        //     top: spHomeHeightTop,
        //     'background-color': '#009a7b'
        // });
        // $('.globalMenus').css('padding-top', spHomeHeight);
    } else {

        // $(searchForm).css('height', eleHeight);
        // $mainContent.css('padding-top', eleHeight);
    }
}

//transformYだけを取得
export function transformValueFunc(e) {
    var values, matrix, resMatrix;
    values = e.split('(')[1];
    values = values.split(')')[0];
    values = values.split(', ');
    matrix = {
        'transform-y': values[5]
    };
    resMatrix = parseInt(matrix['transform-y']);
    return resMatrix;
}

//符号値を取得
export function signFunc(n) {
    return ! (0 > n) - ! (0 < n);
}

//Scroll時のヘッダー処理
export function headerScrollFunc() {
    let scrollingIncrement;
    let props, resTransY, resSign;
    const $globalMenus = $('#masthead'),
        scrollClassName = 'isScroll';
    indResizeFunc();
    if (2 != responsibleCheck) {
        scrollingIncrement = $(window).scrollTop();
        if (150 < scrollingIncrement) {
            $globalMenus.addClass(scrollClassName);
        } else {
            $globalMenus.removeClass(scrollClassName);
        }

        //サブナビが現在表示されているかどうか
        props = $subNavigations.css('transform');

        // resTransY = transformValueFunc(props);

        //符号を取得
        resSign = signFunc(resTransY);
        if ('1' == resSign) {
            $subNavigations.css('z-index', '-1');
            setTimeout(function() {
                $subNavigations.css('transform', 'translateY(-100%)');
            }, 200);
        }
    } else {
        $globalMenus.removeClass(scrollClassName);
    }
}

//サブナビゲーション
export function globalSubNavFunc() {

    // var resTransY, props, resSign, headerHeight;
    // const jsSubOn = '#jsSubOn';
    // indResizeFunc();
    // //事業案内をクリックした場合の処理
    // $(jsSubOn).on('click', function() {
    //     if (2 != responsibleCheck) {
    //         //ヘッダーの高さを取得
    //         headerHeight = $header.outerHeight(true);
    //         //サブナビが現在表示されているかどうか
    //         props = $subNavigations.css('transform');
    //         resTransY = transformValueFunc(props);
    //         //符号を取得
    //         resSign = signFunc(resTransY);
    //         if ('-1' == resSign) {
    //             $subNavigations.css(
    //                 'transform',
    //                 'translateY(' + headerHeight + 'px)'
    //             );
    //             setTimeout(function() {
    //                 $subNavigations.css('z-index', '1');
    //             }, 700);
    //         } else {
    //             $subNavigations.css('z-index', '-1');
    //             setTimeout(function() {
    //                 $subNavigations.css('transform', 'translateY(-100%)');
    //             }, 200);
    //         }
    //     } else {
    //         $subNavigations
    //             .slideToggle(700)
    //             .prev()
    //             .find('.txt')
    //             .toggleClass('active');
    //     }
    // });
}

//検索処理
export function searchFunc() {

    // var JsSearch, props, resTransY, resSign;
    // indResizeFunc();
    // JsSearch = '#JsSearch';
    // $(JsSearch).on('click', function() {
    //     props = $('.formBox').css('transform');
    //     //transformYだけを取得
    //     resTransY = transformValueFunc(props);
    //     //符号を取得
    //     resSign = signFunc(resTransY);
    //     if ('-1' == resSign || '0' == resSign) {
    //         $('.formBox').css('transform', 'translateY(100%)');
    //     } else {
    //         $('.formBox').css('transform', 'translateY(0)');
    //     }
    // });
}

// SP時Menuボタンを押下時の設定
export function spGlobalNavFunc() {
    let $spMenuBtn, props, resTransY, resSign, $spAdjustColumn, scrollpos;

    //spMenuBtn = '.spMenuBtn';
    $spMenuBtn = $('#spBtn');
    $spAdjustColumn = $('.spAdjustColumn');

    //spMenuBtn = document.getElementById('spBtn').contentDocument;

    $spMenuBtn.on('click', function() {

        //サブナビが現在表示されているかどうか
        $(this).toggleClass('isActive');
        $header.toggleClass('isActive');

        // props = $spAdjustColumn.css('transform');
        $('#siteNavigation').fadeToggle();

        let navInnerHeight =
            $(window).height() - $('.site_branding').outerHeight(true);
        $spAdjustColumn.css('height', navInnerHeight);
    });
}
