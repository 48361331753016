import $ from 'jquery';
require('slick-carousel');

export function idxFunc() {

    // メインビジュアルのスライドショー
    $('#idxKeySlide').slick({
        autoplay: true,
        fade: true,
        pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false
                }
            }
        ]
    });

    // ニュースのスライドショー
    $('#newsSlide').slick({
        centerMode: true,
        variableWidth: true,
        arrows: false
    });
}
