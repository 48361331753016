/* ----------------------------------------------------------------------------------
透過ホバー
---------------------------------------------------------------------------------- */
export function hoverFunc() {
    $('a.jsHover').hover(
        function() {
            $(this)
                .stop()
                .animate(
                    {
                        opacity: '0.5'
                    },
                    300
                );
        },
        function() {
            $(this)
                .stop()
                .animate(
                    {
                        opacity: '1'
                    },
                    300
                );
        }
    );
}

//電話リンクを無効化
export function telLinkFunc() {
    $('a[href^="tel:"]')
        .css('cursor', 'default')
        .on('click', function(e) {
            e.preventDefault();
        });
}

//UA判定でhoverAction制御
export function uaZoomFunc() {
    const $uaZoom = $('.uaZoom'),
        uaZoomBox = $('.uaZoomBox');

    const addActive = $e => {
        $e.find('.uaZoomBox').addClass('activeZoom');
    };

    $uaZoom.hover(
        function() {
            addActive($(this));
        },
        function() {
            $(this)
                .find('.uaZoomBox')
                .removeClass('activeZoom');
        }
    );

    //背景画像の場合
    const $uaZoomBack = $('.uaZoomBack');
    $uaZoomBack.find('a').hover(
        function() {
            addActive($uaZoomBack);
        },
        function() {
            $uaZoomBack.find('.uaZoomBox').removeClass('activeZoom');
        }
    );
}
